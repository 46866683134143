<template>
  <Page>
    <Navbar title="加入车队" />
    <div class="fleet-wrap pa-16">
      <img :src="require('@/assets/jrcd.png')" alt="" />
      <div class="join-item mt-20 pa-16">
        <div class="d-flex mb-24">
          <div class="text_primary join-item-tag py-4 px-8">方式一</div>
          <div class="font-16 lh-28 ml-16">扫码加入车队</div>
        </div>
        <Button size="large" @click="handleClickQRCode" type="primary">扫码车队二维码</Button>
      </div>
      <div class="join-item mt-20 pa-16">
        <div class="d-flex mb-24">
          <div class="text_primary join-item-tag py-4 px-8">方式二</div>
          <div class="font-16 lh-28 ml-16">查找车队</div>
        </div>
        <Button size="large" @click="visible = true" type="primary">车队长手机号查询</Button>
      </div>
      <Popup v-model="visible" @submit="handleSearchByCarrierPhone">
        <div class="carrier-form py-40 px-20">
          <div class="font-16 fw-400">请输入车队长手机号</div>
          <van-form class="input-border">
            <van-field
              required
              v-model="carrierPhoneRef"
              input-align="left"
              placeholder="请输入手机号"
              :rules="FormObj.rules"
            />
          </van-form>
          <div class="buttons d-flex font-16 text-center lh-48 justify-space-between">
            <div class="button-cancel text_muted fw-500" @click="visible = false">取消</div>
            <div class="button-ok fw-500" @click="handleSearchByCarrierPhone">查询</div>
          </div>
        </div>
      </Popup>
    </div>
  </Page>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import { PageEnum } from '@/enums/pageEnum';
import { Button, Popup, Toast } from 'vant';
import ClientApp from '@/client/index';

export default defineComponent({
  name: 'driver-fleet',
  components: {
    Button,
    Popup,
    Toast
  },
  setup(_, ctx) {
    const visible = ref(false);
    const carrierPhoneRef = ref();
    const FormObj = {
      rules: [
        { required: true, message: '手机号不能为空!' },
        {
          // pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
          pattern: /^[1][0-9]{10}$/,
          message: '手机号不合法!'
        }
      ]
    };
    const handleClickQRCode = async () => {
      try {
        const res = await ClientApp.startScan();
        window.location.href = res;
      } catch (err) {
        Toast.fail(err);
      }
    };
    const handleSearchByCarrierPhone = () => {
      if (FormObj.rules[1].pattern.test(carrierPhoneRef.value)) {
        ctx.root.$router.push({
          path: PageEnum.DRIVER_FLEET_RESULT,
          query: {
            phone: carrierPhoneRef.value
          }
        });
      } else {
        Toast.fail('手机号不合法!');
      }
    };

    return {
      visible,
      FormObj,
      carrierPhoneRef,
      PageEnum,
      handleClickQRCode,
      handleSearchByCarrierPhone
    };
  }
});
</script>
<style lang="less">
.fleet-wrap {
  img {
    height: 1.36rem;
  }
  .van-popup--center {
    border-radius: 4px;
  }
  .join-item {
    width: 100%;
    border-radius: 4px;
    height: 1.4rem;
    background: white;
    box-shadow: 0px 4px 12px 0px rgba(223, 233, 245, 0.2);
    .join-item-tag {
      background: rgba(64, 152, 255, 0.1);
      border-radius: 4px;
    }
  }
  .carrier-form {
    width: 2.7rem;
    height: 2.04rem;
    border-radius: 4px;
    box-shadow: 0px 2px 20px 0px #ebeef2;
    .buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0%;
      height: 0.48rem;
      .button-cancel {
        background: #f6f8fa;
        flex: 1;
      }
      .button-ok {
        background: #0076ff;
        color: white;
        flex: 1;
        box-shadow: 0px 1px 0px 0px rgba(188, 188, 188, 0.5) inset;
      }
    }
  }
}
</style>
